<template>
  <v-card light min-height="250px" width="494px" class="mt-5">

    <v-card-title>Calculate the Cost</v-card-title>

    <v-card-text>

      <div class="calc-services">
        <v-btn :outlined="service === 'Writing'" :color="service === 'Writing' ? essayOrange : ''" text class="calc-btn-text py-7 rounded-lg" large @click="service = 'Writing'" >
          Writing
        </v-btn>
        <v-btn :outlined="service === 'Rewriting'" :color="service === 'Rewriting' ? essayOrange : ''" text class="calc-btn-text py-7 rounded-lg" @click="service = 'Rewriting'">
          Rewriting
        </v-btn>
        <v-btn :outlined="service === 'Editing'" :color="service === 'Editing' ? essayOrange : ''" text class="calc-btn-text py-7 rounded-lg" @click="service = 'Editing'">
          Editing
         </v-btn>
      </div>

      <div class="mt-5">
        <v-select
          v-model="assignment_type"
          solo
          single-line
          placeholder=""
          item-text="title"
          item-value="title"
          :background-color="essayGrey"
          class="rounded-lg"
          required
          :items="assignItems"
          light
        >
                      
        </v-select>
      </div>

      <div class="d-flex">
        <v-select
          v-model="level"
          solo
          single-line
          placeholder=""
          :items="levelItems"
          item-text="title"
          item-value="title"
          :background-color="essayGrey"
          class="rounded-lg pt-0"
          required
          light
        >
                      
        </v-select>
                      
        <v-select
          v-model="final_date"
          solo
          single-line
          placeholder=""
          item-text="text"
          item-value="value"
          :background-color="essayGrey" 
          class="rounded-lg ml-2 pt-0"
          required
          :items="finalDates"
          light
         >
                      
        </v-select>
      </div>

      <div class="mt-2">

        <span class="font-weight-bold">Pages</span>

        <div class="mt-5 d-flex">

          <div class="d-flex calc-services py-2 px-5">
            <v-btn icon @click="decrement">
              <v-icon>mdi-minus</v-icon>
            </v-btn>

            <span class="mx-5 mt-2">{{ pages }} </span>

            <v-btn icon  @click="increment">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <span class="mt-3">275 words</span>
        </div>
      </div>

      <div class="mt-2 d-flex">
        <v-spacer></v-spacer>
        <span class="text-h5 font-weight-bold"> $ {{ amount }}</span>
      </div>

      <div class="mt-4">
        <v-btn block :color="essayOrange" dark x-large class="tex-h3 font-weight-bold" to="/order/draft">
          Order with us
        </v-btn>
      </div>
    </v-card-text>

  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import colorMxn from '@/mixins/colorMxn';

export default {
  name: 'CalcComponent',
  mixins: [colorMxn],
  data() {
    return {
      assignItems: [],
      assignment_type: 'Essay (any type)',
      levelItems: [],
      finalDates: [
        {
          'text': '2 Weeks',
          'value': '2W'
        },
        {
          'text': '1 Day',
          'value': '1D'
        },
        {
          'text': '2 Day',
          'value': '2D'
        },
        {
          'text': '3 Day',
          'value': '4D'
        },
        {
          'text': '5 Day',
          'value': '5D'
        },
        {
          'text': '7 Day',
          'value': '7D'
        },
      ],
      final_date: '2W',
      level: 'College',
      pages: 1,
      amount: 11.4,
      service: 'Writing',
    }
  },
  computed: {
    ...mapGetters(['getDirectory', 'getAmount']),
  },
  watch: {
    service(oldval, newVal) {
      if (oldval !== newVal) {
        this.calcPrice()
      }
    },
    getAmount(val) {
      this.amount = val;
    },
    pages(oldval, newVal) {
      if (oldval !== newVal) {
        this.calcPrice()
      }
    }
  },
  mounted() {
    this.assignItems = this.getDirectory ? this.getDirectory.types : this.assignItems;
    this.levelItems = this.getDirectory ? this.getDirectory.levels : this.levelItems;

  },
  methods: {
    ...mapMutations(['setAmount', 'setOrderObject']),
    increment() {
      this.pages = this.pages + 1;
      this.calcPrice();
    },
    decrement() {
      this.pages = this.pages - 1;
      this.calcPrice();
    },
    calcPrice() {
      const writing = 10.4;
      const editing = 10.4;
      const rewriting = 6.98;
      const proofreading = 4.98;

      let amount = 10.4;
      switch (this.service) {
        case 'Writing': 
          amount = parseInt(this.pages) * writing; 
          break;
        case 'Rewriting': 
          amount = parseInt(this.pages) * rewriting; 
          break;
        case 'Editing': 
          amount = parseInt(this.pages) * editing; 
          break;
        case 'Proofreading': 
          amount = parseInt(this.pages) * proofreading; 
          break;
        default:
          break;
      }
      this.setAmount(amount);

      const OrderOject = {
        assignment_type: this.assignment_type,
        level: this.level,
        pages: this.pages,
        service: this.service,
      };
      
      this.setOrderObject(OrderOject);
    }
  }
}
</script>